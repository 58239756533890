interface IGetQueryArgs {
  storageSize: number;
  productSlug: string;
  color: string | undefined;
  productIdentifiers?: string[];
}

// By default elastic returns 10 hits. This property was added so that we can get more hits.
// https://www.elastic.co/guide/en/elasticsearch/reference/current/search-search.html#request-body-search-query
const MAX_RESULT_SIZE = 1000;

const getShopsFilter = ({
  color
}: Pick<IGetQueryArgs, 'productIdentifiers' | 'color'>) => {
  if (color) {
    return {
      term: {
        color: color
      }
    };
  }
  return { match_all: {} };
};

export const addProductIdentifierQuery = (
  productIdentifiers: string[] | undefined
): object => {
  if (!productIdentifiers || productIdentifiers.length === 0) {
    return {};
  }

  return {
    sort: {
      priceTotal: 'asc'
    },
    size: MAX_RESULT_SIZE,
    query: {
      bool: {
        should: productIdentifiers.map(productIdentifier => ({
          term: {
            productIdentifier
          }
        }))
      }
    }
  };
};

const addPostFilter = (color: string | undefined) => {
  if (!color) {
    return {};
  }
  return { post_filter: { term: { color: color } } };
};

export const getFilterQuery = ({
  storageSize,
  productSlug,
  color,
  productIdentifiers
}: IGetQueryArgs): object => {
  let query: object = {
    aggs: {
      shops: {
        filter: getShopsFilter({
          productIdentifiers,
          color
        }),
        aggs: {
          shops: {
            terms: { field: 'shopName' },
            aggs: {
              shopName_hits: { top_hits: { size: 1 } },
              maxPrice: { max: { field: 'price' } }
            }
          }
        }
      },
      color: { terms: { field: 'color' } },
      color_count: { cardinality: { field: 'color' } }
    },
    ...addPostFilter(color),
    ...addProductIdentifierQuery(productIdentifiers)
  };

  if (productIdentifiers && productIdentifiers.length > 0) {
    return query;
  }

  return {
    ...query,
    size: MAX_RESULT_SIZE,
    query: {
      bool: {
        filter: [
          { term: { productSlug: productSlug } },
          { term: { storageSize: storageSize } }
        ]
      }
    }
  };
};

export const getListQuery = ({
  productIdentifiers,
  isRefurbished
}: {
  isRefurbished: boolean | undefined;
  productIdentifiers: string[];
}): object => {
  if (!productIdentifiers || productIdentifiers.length === 0) {
    return { size: 0 };
  }

  return {
    size: MAX_RESULT_SIZE,
    aggs: {
      isRefurbished: { terms: { field: 'isRefurbished' } }
    },
    ...(isRefurbished !== undefined
      ? { post_filter: { term: { isRefurbished: isRefurbished } } }
      : {}),
    ...addProductIdentifierQuery(productIdentifiers)
  };
};
